import React from "react";
import Layout from "../components/layout";

export default function Showcase() {
    return (
        <Layout>
            <div className="container">
                <h2 style={{ paddingTop: `2rem`}}>Past Projects</h2>
            </div>
        </Layout>
    )
}